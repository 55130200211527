import React, { useEffect, useRef, useState } from 'react';
import '../App.css';
import ChatInput from "./ChatInput";
import ChatMessage from "./ChatMessage";
import ChatStatusIndicator from "./ChatStatusIndicator";
import Loading from "./Loading";
import { useThread } from '../hooks/useThread';
import { useRunPolling } from '../hooks/useRunPolling';
// import { useRunRequiredActionsProcessing } from '../hooks/useRunRequiredActionsProcessing';
import { useRunStatus } from '../hooks/useRunStatus';
import { get_all_threads, overviewApi, postMessage } from "../services/api";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Loader from './Loader';
import NewChatForm from './NewChatForm';
import toast, { Toaster } from 'react-hot-toast'
import ThreadDeets from './ThreadDeets';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FileListComponent from './FileListComponent';
// import { CircularProgress } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import Tokens from './Tokens';
import ErrorDialog from './ErrorDialog';
import { runFinishedStates } from '../hooks/constants';
import { logout } from '../redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';

const drawerWidth = 290;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(0),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        maxWidth: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

function Chat({ appLoading, setAppLoading }) {
    const dispatch = useDispatch()
    const user = useSelector((state) => state.oizomGptUser);
    const messageDiv = useRef(null)
    const [tab, setTab] = useState(0)
    const [newChat, setNewChat] = useState(false)
    const [mainLoading, setMainLoading] = useState(true)
    const [threadList, setThreadList] = useState([])
    const [run, setRun] = useState(undefined);
    const { threadId, messages, clearThread, setMessages, changeThread, thread, settingNewThread, setError, error } = useThread(run, setRun, setMainLoading, setNewChat, threadList);
    useRunPolling(threadId, run, setRun, setError, error);
    // useRunRequiredActionsProcessing(run, setRun, setActionMessages);
    const { status, processing, setStatus, setProcessing } = useRunStatus(run, error);
    const isMobile = window.innerWidth < 768;
    const [open, setOpen] = useState(!isMobile);
    const [data, setData] = useState({
        userID: user.userID,
        orgID: user.orgID,
        title: undefined,
        keys: [],
        deviceId: [],
        gte: undefined,
        lte: undefined
    })
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const openDialog = async () => {
        document.getElementById('errorDialog').click()

    }
    const messagesList = messages?.toReversed()
        .filter((message) => message.hidden !== true)
        .map((message) => {
            return <ChatMessage
                message={message.content}
                role={message.role}
                type={message.type}
                annotations={message.annotations}
            />
        })
    useEffect(() => {
        setAppLoading(true)
        const fetchThreadsTokens = async () => {
            if (Math.floor(new Date().getTime()/1000.0) > user?.token?.expires_in) {
                toast.error("Token Expired. Please login again");
                dispatch(logout())
            }
            const threadArray = await get_all_threads(data?.userID)
            if (threadArray.error) {
                setError(threadArray.error)
                return
            }

            setThreadList(threadArray)
            console.log(threadArray);
            if (threadArray.length < 1) {
                setMainLoading(false)
                console.log("threadlength");
                setNewChat(true)
                clearThread({})
            }

        }

        !newChat && fetchThreadsTokens()
        setAppLoading(false)

    }, [])

    useEffect(() => {
        openDialog()
    }, [error])

    useEffect(() => {
        const scrollToBottom = () => {
            document.documentElement.scrollTop = document.documentElement.scrollHeight;
        }
        scrollToBottom()
    }, [messages, error, status])

    return (
        <Box className='h-screen' ref={messageDiv} sx={{ display: 'flex' }}>
            <Toaster
                toastOptions={{
                    duration: 2000,
                    className: '',
                    style: {
                        padding: '5px',
                        color: 'white',
                        backgroundColor: '#00B906'
                    },
                }} />
            <CssBaseline />
            <AppBar position="fixed" open={open} sx={{ backgroundColor: "white", boxShadow: 'none', maxHeight: '70px' }}>
                <Toolbar className='fade-in' sx={{ display: "flex", backgroundColor: 'white', justifyContent: "space-between" }}>
                    <div style={{ display: "flex" }}>
                        <IconButton

                            aria-label="open drawer"
                            onClick={(handleDrawerOpen)}
                            edge="start"
                            sx={{ mr: 2, color: "#00B906", ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        {!open && <Typography sx={{ fontSize: "x-large", marginRight: "100px" }} className='text-[#00B906] hover:[#B9E937] font-bold' variant="h6" component="div">
                            <b>OizomGPT</b>
                        </Typography>}
                    </div>

                    {newChat ? <div className='fade-in' style={{ display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "center", width: "75%" }}>
                        {<ThreadDeets newChat={newChat} data={data} />}

                    </div> : <div className='fade-in' style={{ display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "center", width: "75%" }}>
                        {<ThreadDeets newChat={newChat} data={thread !== undefined && thread.thread.metadata} />}

                    </div>}
                    <Tokens newChat={newChat} user={data?.userID} messages={messages} />
                </Toolbar>
                {!newChat && <Box className='fade-in' sx={{ borderBottom: 1, borderColor: 'divider', width: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: 'white' }}>
                    <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Chat" />
                        <Tab label="Files" />
                    </Tabs>
                </Box>}
            </AppBar>

            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
                className='fade-in'
            >
                <div>
                    <DrawerHeader className='fade-in' sx={{ display: "flex", justifyContent: "space-between", paddingLeft: "20px", marginBottom: 5, paddingTop: "15px" }}>
                        <Typography style={{ fontWeight: 700, fontSize: "x-large" }} className='text-[#00B906] hover:[#B9E937] font-bold mx-10' variant="h6" noWrap component="div">
                            <b>OizomGPT</b>
                        </Typography>
                        <IconButton size='small' onClick={handleDrawerClose}>
                            <ChevronLeftIcon className='hover:opacity-100 opacity-50' fontSize='sm' />
                        </IconButton>
                    </DrawerHeader>

                    <List className='fade-in' sx={{ padding: 0, overflow: 'auto' }}>
                        <ListItem className='hover:bg-[DFFBDC]'>
                            <ListItemButton onClick={() => {
                                setData({
                                    userID: user.userID,
                                    orgID: user.orgID,
                                    title: undefined,
                                    keys: [],
                                    deviceId: [],
                                    gte: undefined,
                                    lte: undefined
                                })
                                setNewChat(true)
                                setMainLoading(false)
                            }} sx={{ borderRadius: '10px', display: "flex", justifyContent: "space-between", fontSize: "medium", fontWeight: 600 }} >
                                New Chat

                                <EditNoteIcon color='success' />
                            </ListItemButton>

                        </ListItem>
                        <Divider sx={{ width: "80%", margin: "0px auto 5px" }} />
                        {threadList?.map((thread, index) => (

                            <ListItem sx={{
                                padding: "1px 10px",
                                margin: "3px 0"
                            }} key={thread?.ThreadID} disablePadding>
                                <ListItemButton sx={{
                                    borderRadius: "10px",
                                    backgroundColor: thread?.ThreadID === threadId && !newChat ? "#Ddffdf" : "",
                                    display: "flex", justifyContent: "space-between", fontSize: "medium", fontWeight: 500
                                }} onClick={() => {
                                    changeThread(thread?.ThreadID)
                                    setNewChat(false)
                                }} >
                                    {thread?.Title}
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </div>


                <div style={{ display: "flex", flexDirection: "column", justifySelf: 'flex-end' }}>
                    <div style={{ justifyContent: 'space-between', fontSize: 'medium', flexWrap: 'wrap', gap: 10 }} className='w-[100%] flex align-items-center py-auto px-5 text-large, py-[15px]'>
                        <div>
                            <UpgradeIcon sx={{ marginRight: '10px' }} />
                            Upgrade
                        </div>
                    </div>
                    <div style={{ justifyContent: 'space-between', fontSize: 'large' }} className='w-[100%] flex align-items-center py-auto px-5 text-large, py-[15px]'>
                        <div style={{ paddingTop: 5 }}>
                            <AccountCircleIcon sx={{ marginRight: '10px' }} />
                            {user.name}
                        </div>
                        <IconButton>
                            <LogoutIcon onClick={() => dispatch(logout())} sx={{ cursor: 'pointer', justifySelf: 'flex-end' }} />

                        </IconButton>
                    </div>
                </div>

            </Drawer>

            {tab === 0 ?
                mainLoading ? <Main className='fade-in' open={open}>
                    <Loader />
                </Main> : <Main className='h-screen fade-in' open={open}>
                    <DrawerHeader />

                    <div ref={messageDiv} className="fade-in md:container md:mx-auto lg:px-40 h-[90%]  flex flex-col pt-[8vh]">
                        <div style={{ flexDirection: newChat ? 'column' : 'column-reverse', justifyContent: newChat ? 'flex-end' : 'flex-start' }} className="flex grow overflow-scroll">
                            {status !== undefined && (
                                <ChatStatusIndicator
                                    status={status}
                                />
                            )}
                            {!newChat ? messagesList : <NewChatForm clearThread={clearThread} setNewChat={setNewChat} data={data} setData={setData} settingNewThread={settingNewThread} setMainLoading={setMainLoading} />}
                        </div>
                        <div className="mb-8">
                            {!newChat && <ChatInput
                                onSend={async (message) => {
                                    if (message === "") return;

                                    await postMessage(threadId, message, data.userID).then(
                                        (res) => {
                                            if (Object.keys(res).includes('error')) {
                                                setError(res.error)
                                                setStatus(undefined)
                                            } else setRun(res)
                                        }
                                    );
                                }}
                                setStatus={setStatus}
                                disabled={processing}
                                setMessages={setMessages}
                                messages={messages}
                                setError={setError}
                            />}
                        </div>
                    </div>
                </Main>
                : ''}
            {tab === 1 && thread.thread.id !== undefined ?
                <Main className='h-screen' open={open}><FileListComponent thread={thread.thread.id} messages={messagesList} /> </Main> : ''}
            <ErrorDialog error={error} setError={setError} />
        </Box>

    )
}

export default Chat;
