import React from 'react'
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { useState } from 'react';
import { fetchFile, get_thread_files } from '../services/api';
import Loader from './Loader';
import { IconButton } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ChatChart from './ChatChart';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts'

function extractJsonFromMarkdown(message) {
    const jsonStringWithoutComments = message.replace(/\/\/.*?\n|\/\*.*?\*\//g, '');
    const jsonObject = JSON.parse(jsonStringWithoutComments.substring(jsonStringWithoutComments.indexOf('{')));
    return jsonObject;
}
function BasicList({ array, chartList }) {

    return (
        <Box sx={{ width: '100%', display: "flex", height: "fit-content", pt: 15, px: 2, flexWrap: "wrap", gap: 5 }}>
            {chartList.map((chart) => {
                const jsonchart = extractJsonFromMarkdown(chart);
                return <div style={{ width: "95%" }} ><HighchartsReact highcharts={Highcharts} options={jsonchart} /></div>
            })}
            {array.length < 1 ? <div style={{ fontSize: "25px", color: 'grey', height: "fit-content", width: '100%', position: 'relative', top: '100px', display: "flex", justifyContent: "center", alignItems: "center" }}>No files present in this thread.</div> : array.map((file) => {
                return <IconButton onClick={() => getLinkDownload(file.id, file.name)} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", borderRadius: "20px", color: "#424242", px: 5, py: 3 }}  >
                    <InsertDriveFileIcon sx={{ fontSize: "10rem" }} />
                    <p style={{ fontSize: "1rem" }}>{file.name.slice(0, 20)}</p>
                </IconButton>
            })}
        </Box>
    );
}

const FileListComponent = ({ thread, messages }) => {
    const [fileListLoading, setFileListLoading] = useState(true)
    const [fileList, setFileList] = useState([])
    const chartList = messages.filter(message => message.props.type === 'chart').map(message => message.props.message)
    useEffect(() => {
        const fetchFiles = async () => {
            const filearray = await get_thread_files(thread)
            setFileList(filearray)
            setFileListLoading(false)
        }
        fetchFiles()
    }, [thread])
    return fileListLoading ? <Loader /> : <BasicList array={fileList} chartList={chartList} />

}

const getLinkDownload = async (file_id, file_name) => {
    const response_file = await fetchFile(file_id)
    const blob = await response_file.blob();

    // Create a temporary URL for the Blob
    const blobUrl = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const tempAnchor = document.createElement('a');
    tempAnchor.href = blobUrl;
    tempAnchor.setAttribute('download', file_name); // Specify the filename
    tempAnchor.style.display = 'none';
    document.body.appendChild(tempAnchor);

    // Simulate click on the anchor to initiate download
    tempAnchor.click();

    // Clean up
    URL.revokeObjectURL(blobUrl);
    tempAnchor.remove();
}

export default FileListComponent