import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import LoginPage from './components/LoginPage';
import { persistor, store } from './redux/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';


const root = ReactDOM.createRoot(document.getElementById('root'));
const user = true
root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
            {user ? <App /> : <LoginPage />}
        </PersistGate>
    </Provider>
);