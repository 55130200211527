import React, { useState } from 'react'
import LoginPage from './components/LoginPage'
import Chat from './components/Chat'
import { useSelector } from 'react-redux';
import Loader from './components/Loader';

const App = () => {
  const user = useSelector((state) => state.oizomGptUser.userID);
  const [appLoading, setAppLoading] = useState(false)
  return (
    appLoading ? <Loader/> : user ? <Chat setAppLoading={setAppLoading} appLoading={appLoading} /> : <LoginPage setAppLoading={setAppLoading} appLoading={appLoading} />
  )
}

export default App