import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const ChatChart = ({ options }) => {
    console.log('====================================');
    console.log(options);
    console.log('====================================');
    var newOptions = options
    if (newOptions.plotOptions) {
        Object.keys(newOptions.plotOptions).forEach(function(seriesType) {
          if (newOptions.plotOptions[seriesType].dataLabels) {
            newOptions.plotOptions[seriesType].dataLabels.enabled = false;
          }
        });
      }

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={newOptions}
            />
        </div>
    )
}

export default ChatChart