import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userID: null
}
// {
//     userID: undefined,
//     units: undefined,
//     tokens: undefined,
//     deviceType: undefined,
// }

export const userSlice = createSlice({
    name: 'oizomGptUser',
    initialState,
    reducers: {
        login: (state, action) => {
            console.log(action.payload)
            state.userID = action.payload.userID
            state.orgID = action.payload.orgID
            state.name = action.payload.name
            state.units = action.payload.units
            state.token = action.payload.token
            state.overview = action.payload.overview
        },
        logout: (state) => {

            return null
        },
    },
})

// Action creators are generated for each case reducer function
export const { login, logout } = userSlice.actions

export default userSlice.reducer