import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';


const Loader = () => {
    return (
        <div style={{ display: "flex", position: "relative", top: "50vh", left: "50%" }} ><CircularProgress sx={{ color: "#B9E937" }} /></div>
    )
}

export default Loader