import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import deviceInfo from '../services/deviceType.json'

export default function OutlinedCard({ data }) {
    const dataType = data.type || null
    const userData = useSelector((state) => state.oizomGptUser.overview)
    const userUnits = useSelector((state) => state.oizomGptUser.units)
    const deviceTypeId = deviceInfo?.filter(device => device.key === dataType)[0]?.deviceTypeId;
    return (
        <Box sx={{ minWidth: 275 }}>
            <Card variant="outlined" sx={{ borderColor: "#00B906" }}><React.Fragment>
                <CardContent>
                    <Typography sx={{ mb: 1 }} variant="h5" component="div">
                        {data?.title}
                    </Typography>
                    <Typography color="primary" sx={{ mb: 1 }} variant="body2">
                        {new Date(data.gte * 1000).toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false
                        })} ~ {new Date(data.lte * 1000).toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false
                        })}
                    </Typography>
                    <div style={{ margin: "5px 1px", display: "flex", flexWrap: "wrap", gap: "3px" }}>
                        {data.keys.map((key) => <Chip sx={{ backgroundColor: "#00B906", color: "white" }} variant='contained' label={userUnits[deviceTypeId][key].flabel || key} />)}

                    </div>
                    <div style={{ margin: "5px 1px", display: "flex", flexWrap: "wrap", gap: "3px" }}>
                        {data.deviceId.map((key) => <Chip sx={{ borderColor: "#00B906", borderWidth: "2px" }} variant='outlined' label={userData.find((obj) => obj.deviceId === key)?.label} />)}
                    </div>
                </CardContent>
            </React.Fragment></Card>
        </Box>
    );
}