import React, { useEffect, useState } from 'react'
import { getUserTokens } from '../services/api'
import { CircularProgress } from '@mui/material'

const Tokens = ({ newChat, user, messages }) => {

    const [tokens, setTokens] = useState({ used: null, left: null })
    useEffect(() => {
        const fetchThreadsTokens = async () => {
            const tokens = await getUserTokens(user)
            if (tokens) setTokens({ used: tokens.total_tokens, left: tokens.tokens_left })
            else {
                setTokens({ used: '-', left: '-' })

            }
        }
        !newChat && fetchThreadsTokens()

    }, [messages])
    return (
        <div style={{ display: 'flex', width: '20%', justifyContent: 'space-evenly', margin: '0px 15px 0px 0px' }}>
            <div className='fade-in' style={{ color: 'black', display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "center", margin: "0px 5px", paddingTop: '10px', backgroundColor: 'white', fontWeight: 600 }}><p style={{ color: '#00B906' }}>Used</p><p id='tokensUsed' >{tokens.used === null ? <CircularProgress size={'small'} /> : tokens.used}</p></div>
            <div className='fade-in' style={{ color: 'black', display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "center", margin: "0px 5px", paddingTop: '10px', backgroundColor: 'white', fontWeight: 600 }}><p style={{ color: '#00B906' }}>Left</p><p id='tokensLeft'>{tokens.left == null ? <CircularProgress size={'small'} /> : tokens.left}</p></div>
        </div>
    )
}

export default Tokens