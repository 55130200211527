import React, { useState } from 'react'
import { MdSend } from "react-icons/md";
import MultiSelect from './MultiSelect';
import { Button, IconButton, TextField } from '@mui/material';
import ChatMessage from './ChatMessage';
import toast from 'react-hot-toast';
import OutlinedCard from './OutlinedCard';
import ReplayIcon from '@mui/icons-material/Replay';
import { useSelector } from 'react-redux';
import deviceInfo from '../services/deviceType.json'

const NewChatForm = ({ data, setData, clearThread, setNewChat, settingNewThread, setMainLoading }) => {
    const unitKeys = useSelector((state) => state.oizomGptUser.units)
    const userData = useSelector((state) => state.oizomGptUser.overview)
    const userInfo = useSelector((state) => state.oizomGptUser)
    const deviceTypes = Array.from(new Set(userData.map(device => device.deviceType)))
    const [times, setTimes] = useState({ gte: undefined, lte: undefined })
    const [keys, setKeys] = useState([])
    const [devices, setDevices] = useState([])
    const [type, setType] = useState("")
    const [typeId, setTypeId] = useState(null)
    

    return (<div style={{ overflowY: 'auto' }}>
        <>
            <ChatMessage message={"Welcome to OizomGPT, what would you like to name this thread?"} role={"assistant"} type={"text"} />
            <form
                className="rounded-lg p-2 flex flex-col m-2 text-[#424242]"
                style={{ display: "flex", alignItems: "flex-end" }}
                onSubmit={async (e) => {
                    e.preventDefault();
                    setData({ ...data, title: document.getElementsByName('threadTitle')[0].value })
                }}
                autoComplete="off"
            >
                <div style={{ display: "flex", margin: "7px 0" }}>
                    <TextField size='small' color='success' label={data.title !== undefined ? data.title : "Title"} type="text" name='threadTitle' disabled={data.title !== undefined} required />
                </div>

                {data.title === undefined && <button
                    type='submit'
                    style={{ width: "fit-content" }}
                    className={
                        "bg-[#00B906] " +
                        "text-white " +
                        "font-bold " +
                        "py-2 px-4 " +
                        "rounded " +
                        "hover:bg-[#B9E937] " +
                        "disabled:bg-slate-600 " +
                        "disabled:text-slate-400"
                    }
                >
                    <MdSend />
                </button>}
            </form>
        </>

        {data.title !== undefined && <>
            <ChatMessage message={"Thank you! Can you please give me which type of devices you want to analyze?"} role={"assistant"} type={"text"} />
            <form className="rounded-lg p-2 flex flex-col m-2 text-[#424242]"
                style={{ display: "flex", alignItems: "flex-end" }}
                onSubmit={async (e) => {
                    e.preventDefault();
                    if (type === undefined || deviceTypes.includes(type) === false) {
                        toast("Please check and provide valid data.");
                        return
                    }
                    setTypeId(deviceInfo.filter(device =>
                        device.key === type
                    )[0].deviceTypeId)
                    setData({ ...data, type: type })

                }}
                autoComplete="off"><div style={{ display: "flex" }} color='success' >
                    <MultiSelect list={deviceTypes} disabled={data.type !== undefined} label={"Device Type"} data={type} setData={setType} multiple={false} /></div>
                {data.type === undefined &&
                    <button
                        type='submit'
                        style={{ width: "fit-content" }}
                        className={
                            "bg-[#00B906] " +
                            "text-white " +
                            "font-bold " +
                            "py-2 px-4 " +
                            "rounded " +
                            "hover:bg-[#B9E937] " +
                            "disabled:bg-slate-600 " +
                            "disabled:text-slate-400"
                        }
                    >
                        <MdSend />
                    </button>}
            </form></>}
        {data.type !== undefined && <>
            <ChatMessage message={"Thank you! Now select the devices."} role={"assistant"} type={"text"} />
            <form className="rounded-lg p-2 flex flex-col m-2 text-[#424242]"
                style={{ display: "flex", alignItems: "flex-end" }}
                onSubmit={async (e) => {
                    e.preventDefault();
                    if (devices.length === 0) {
                        toast("Please check and provide valid data.");
                        return
                    }
                    const matchedDeviceIds = [];

                    // Iterate over devices array
                    userData.forEach(device => {
                        // Check if the device label is in labelsToMatch array
                        if (devices.includes(device.label)) {
                            // If it is, push deviceId to matchedDeviceIds array
                            matchedDeviceIds.push(device.deviceId);
                        }
                    });
                    setData({ ...data, deviceId: matchedDeviceIds })
                }}
                autoComplete="off"><div style={{ display: "flex" }} color='success' >

                    <MultiSelect list={userData.filter(device => device.deviceType === data.type).map((device) => device.label)} disabled={data.deviceId.length !== 0} label={"Device ID"} data={devices} setData={setDevices} multiple={true} />
                </div>
                {data.deviceId.length === 0 &&
                    <button
                        type='submit'
                        style={{ width: "fit-content" }}
                        className={
                            "bg-[#00B906] " +
                            "text-white " +
                            "font-bold " +
                            "py-2 px-4 " +
                            "rounded " +
                            "hover:bg-[#B9E937] " +
                            "disabled:bg-slate-600 " +
                            "disabled:text-slate-400"
                        }
                    >
                        <MdSend />
                    </button>}
            </form></>}
        {data.deviceId.length !== 0 && <>
            <ChatMessage message={"Thank you! Can you please give me which devices and parameters you want me to consider?"} role={"assistant"} type={"text"} />
            <form className="rounded-lg p-2 flex flex-col m-2 text-[#424242]"
                style={{ display: "flex", alignItems: "flex-end" }}
                onSubmit={async (e) => {
                    e.preventDefault();
                    if (keys.length === 0) {
                        toast("Please check and provide valid data.");
                        return
                    }

                    const keyIds = Object.entries(unitKeys[deviceInfo.filter(device => device.key === type)[0].deviceTypeId]).map(entry =>
                        [entry[0], entry[1].flabel]
                    )
                    const newkeys = keys.map(key => keyIds.find(k => k[1] === key)[0])

                    setData({ ...data, keys: newkeys })
                }}
                autoComplete="off"><div style={{ display: "flex" }} color='success' >
                    <MultiSelect list={[
                        ...Array.from(userData
                            .filter(device => device.deviceType === data.type && devices.includes(device.label))
                            .reduce((acc, device) => {
                                device.params.forEach(param => acc.add(param));
                                return acc;
                            }, new Set()))
                            .map((param) => {
                                const unitId = deviceInfo.filter(device => device.key === type)[0].deviceTypeId
                                return unitKeys[unitId][param]?.flabel
                            }),
                    ]} label={"Keys"} data={keys} setData={setKeys} color="success" disabled={data.keys.length !== 0 && data.deviceId.length !== 0} multiple={true} />
                </div>

                {data.keys.length === 0 &&
                    <button
                        type='submit'
                        style={{ width: "fit-content" }}
                        className={
                            "bg-[#00B906] " +
                            "text-white " +
                            "font-bold " +
                            "py-2 px-4 " +
                            "rounded " +
                            "hover:bg-[#B9E937] " +
                            "disabled:bg-slate-600 " +
                            "disabled:text-slate-400"
                        }
                    >
                        <MdSend />
                    </button>}
            </form></>}
        {data.keys.length !== 0 && data.deviceId.length !== undefined && <>
            <ChatMessage message={"Awesome, now can you provide the time range?"} role={"assistant"} type={"text"} />
            <form className="rounded-lg p-2 flex flex-col m-2 text-[#424242]"
                style={{ display: "flex", alignItems: "flex-end" }}
                onSubmit={async (e) => {
                    e.preventDefault();
                    if (times.gte === null || times.lte === null || times.gte > times.lte || times.gte > (Date.now() / 1000)) {

                        toast("Please check and provide valid data.")
                        return
                    }
                    setData({ ...data, gte: times.gte, lte: times.lte })
                }}
                autoComplete="off" action="">
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: "100%", flexWrap: "wrap",
                }}>
                    <div>
                        Start Date : <input onChange={async (e) => {
                            e.preventDefault()
                            const newTime = (Date.parse(e.target.value) / 1000);
                            setTimes({ gte: newTime, lte: times.lte })
                        }} style={{ padding: "7px", borderRadius: "6px", background: "transparent", border: "1px lightgrey solid", margin: "10px" }} type="datetime-local" name="gte" id="gte" placeholder='GTE' />
                    </div>
                    <div>
                        End Date : <input onChange={async (e) => {
                            e.preventDefault()
                            const newTime = Date.parse(e.target.value) / 1000
                            setTimes({ lte: newTime, gte: times.gte });
                        }} style={{ padding: "10px", borderRadius: "6px", background: "transparent", border: "1px lightgrey solid", margin: "10px" }} type="datetime-local" name="lte" id="lte" />
                    </div>
                </div>
                {data.gte === undefined && data.lte === undefined && <button
                    type='submit'
                    style={{ width: "fit-content" }}
                    className={
                        "bg-[#00B906] " +
                        "text-white " +
                        "font-bold " +
                        "py-2 px-4 " +
                        "rounded " +
                        "hover:bg-[#B9E937] " +
                        "disabled:bg-slate-600 " +
                        "disabled:text-slate-400"
                    }
                >
                    <MdSend />
                </button>}
            </form>
        </>}
        {data.gte !== undefined && data.lte !== undefined && <>
            <ChatMessage message={"Please confirm the details."} role={"assistant"} type={"text"} />
            <form className="rounded-lg p-2 flex flex-col m-2 text-[#424242]"
                style={{ display: "flex", alignItems: "flex-end" }}
                onSubmit={async (e) => {
                    
                    const newData = { ...data, utils: unitKeys[typeId], deviceLabels: devices }

                    e.preventDefault();
                    await setMainLoading(true)
                    await clearThread(data)
                    await settingNewThread(newData)
                    await setNewChat(false)
                }}
                autoComplete="off" action="">
                <OutlinedCard data={data} />
                <Button style={{ backgroundColor: "#00B906", color: "white", padding: "6px 12px", margin: "10px 0px" }} type="submit">INITIATE</Button>
                <IconButton onClick={(e) => {
                    setData({
                        userID: userInfo.userID,
                        orgID: userInfo.orgID,
                        title: undefined,
                        keys: [],
                        deviceId: [],
                        gte: undefined,
                        lte: undefined
                    })
                    document.getElementsByName('threadTitle')[0].value = ''
                    setDevices([])
                    setKeys([])
                }}><ReplayIcon /></IconButton>
            </form>
        </>}

    </div>

    )
}

export default NewChatForm