import { SiOpenai } from "react-icons/si";

export default function ChatStatusIndicator({ status }) {

    return (
        <div className="flex justify-center my-1">
            <div className="flex flex-row items-center text-[#00B906]">
                <div className="m-2 animate-spin"><SiOpenai /></div>
                <div>{status}</div>
            </div>
        </div>
    )
}