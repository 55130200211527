import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { loginApi, overviewApi } from '../services/api';
import toast, { Toaster } from 'react-hot-toast';
import { login } from '../redux/userSlice';
import { useEffect } from 'react';
const CryptoJS = require('crypto-js');

function encryptData(data, key) {
  const keyBytes = CryptoJS.enc.Utf8.parse(key.padEnd(32, '\0')); // Ensure the key is 32 bytes
  const iv = CryptoJS.lib.WordArray.random(128 / 8); // Generates a random 128-bit IV

  const encrypted = CryptoJS.AES.encrypt(data, keyBytes, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  // Concatenate IV and encrypted data, then encode in Base64
  const combined = CryptoJS.lib.WordArray.create(iv.words.concat(encrypted.ciphertext.words));
  return CryptoJS.enc.Base64.stringify(combined);
}


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Oizom
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function LoginPage({ appLoading, setAppLoading }) {
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setAppLoading(true)
      const data = new FormData(event.currentTarget);
      if (data.get("email") === "") {
        toast.error("Email cannot be empty")
        setAppLoading(false);
        return
      }
      if (data.get("password") === "") {
        toast.error("Password cannot be empty")
        setAppLoading(false);
        return
      }
      const password = data.get('password');
      const encryptedPassword = encryptData(password, 'JNyG68b3FXsxDh2PmECX6e5GRVQgFFf5');
      const loginResponse = await loginApi({
        email: data.get('email'),
        password: encryptedPassword,
      });
      if (!loginResponse.ok) {
        toast.error("There was trouble logging in. Please try again.")
        setAppLoading(false);
      }
      else if (loginResponse.ok) {
        const loginRes = await loginResponse.json()
        const { token, id, units, org, name } = loginRes;
        const data = await overviewApi(id, token.access_token);
        const overview = data.map(({ deviceId, deviceType, label, data }) => {
          const params = Object.keys(data.payload.d)
          return { deviceId, deviceType, label, params }
        });
        dispatch(login({ token: token, userID: id, units, orgID: org, name, overview: overview }));
        toast.success("Login Successful");
        setAppLoading(false);
      }
    }
    catch (e) {
      console.log(e)
      toast.error("There was trouble logging in. Please try again.")
      setAppLoading(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Toaster />
      <Grid container component="main" sx={{ height: '100vh', justifyContent: 'center', fontFamily: 'Inter' }}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography style={{ fontFamily: 'Inter', fontSize: '30px' }} component="h1" variant="h5">
              <b style={{ color: '#00B906' }}>OizomGPT</b>
            </Typography>
            <Box style={{ fontFamily: 'Inter' }} component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              {error && <Typography color={'error'}>Something went wrong.</Typography>}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: '#00B906' }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              {/* <Copyright sx={{ mt: 5 }} /> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}