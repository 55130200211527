import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Chip } from '@mui/material';
import Popover from './PopHover';
import { useSelector } from 'react-redux';
import deviceInfo from '../services/deviceType.json'

export default function ThreadDeets({ data, newChat }) {
    const dataType = data.type || null
    const userData = useSelector((state) => state.oizomGptUser.overview)
    const userUnits = useSelector((state) => state.oizomGptUser.units)
    const deviceTypeId = deviceInfo?.filter(device => device.key === dataType)[0]?.deviceTypeId;

    return <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", }}>
        <Card variant="outlined" sx={{ borderColor: "transparent", display: "flex", alignContent: "center", justifyContent: "center", width: "100%" }}><React.Fragment>
            <CardContent style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", height: "fit-content", padding: '17px 5px 0px' }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="h6" component="div">
                        <b>{newChat ? document.getElementsByName('threadTitle')[0]?.value : data?.title}</b>
                    </Typography>


                    <Typography color="primary" variant="body2">
                        {typeof (data?.gte) === "string" && new Date(parseInt(data?.gte) * 1000).toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false
                        })} ~ {typeof (data?.lte) === "string" && new Date(parseInt(data?.lte) * 1000).toLocaleString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false
                        })}
                    </Typography>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "3px" }}>
                    {newChat ? (
                        data?.keys?.length > 0 && (
                            <>
                                {data?.keys?.slice(0, 3).map((key) => {

                                    return (
                                        <Chip sx={{ backgroundColor: "#00B906", color: "white" }} variant='contained' label={userUnits[deviceTypeId][key].flabel || key} />
                                    )
                                })}
                                <Popover number={data?.keys?.slice(3).length} items={data?.keys?.slice(3).map((key) => userUnits[deviceTypeId][key].flabel || key)} />
                            </>
                        )
                    ) : (
                        data?.keys?.length > 0 && (
                            <>
                                {data?.keys?.split(',')?.slice(0, 3)?.map((key) => {

                                    if (userUnits[deviceTypeId])
                                        return <Chip sx={{ backgroundColor: "#00B906", color: "white" }} variant='contained' label={userUnits[deviceTypeId][key].flabel || key} />
                                    else
                                        return <Chip sx={{ backgroundColor: "#00B906", color: "white" }} variant='contained' label={key} />
                                }
                                )}
                                <Popover number={data?.keys?.split(',')?.slice(3)?.length} items={data?.keys?.split(',')?.slice(3).map((key) => {
                                    if (userUnits[deviceTypeId])
                                        return userUnits[deviceTypeId][key].flabel
                                    else
                                        return key
                                })} />
                            </>
                        )
                    )}


                    {/* {!newChat && data?.keys?.length > 0 && data?.keys?.split(',')?.slice(0, 3)?.map((key) => <Chip sx={{ backgroundColor: "#00B906", color: "white" }} variant='contained' label={key} />)}<Popover number={data?.keys?.split(',')?.slice(3,)?.length} items={data?.keys?.split(',')?.slice(3,)} />
                    {newChat && data?.keys?.length > 0 && data?.keys?.slice(0, 3).map((key) => <Chip sx={{ backgroundColor: "#00B906", color: "white" }} variant='contained' label={key} />)}<Popover number={data?.keys?.slice(3,).length} items={data?.keys?.slice(3,)} /> */}
                </div>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "3px" }}>

                    {newChat ? (
                        data?.deviceId?.length > 0 && (
                            <>
                                {data?.deviceId?.slice(0, 3).map((key) => {
                                    const label = userData.find((item) => item.deviceId === key)?.label
                                    return (
                                        <Chip sx={{ borderColor: "#00B906", borderWidth: "2px" }} variant='outlined' label={label} />
                                    )
                                })}
                                <Popover number={data?.deviceId?.slice(3).length} items={data?.deviceId?.slice(3).map((key) => userData.find((item) => item.deviceId === key)?.label)} type={'deviceId'} />
                            </>
                        )
                    ) : (
                        data?.deviceId?.length > 0 && (
                            <>
                                {data?.deviceId?.split(',')?.slice(0, 3)?.map((key) => {
                                    const label = userData.find((item) => item.deviceId === key)?.label
                                    return (
                                        <Chip sx={{ borderColor: "#00B906", borderWidth: "2px" }} variant='outlined' label={label} />
                                    )
                                })}
                                <Popover number={data?.deviceId?.split(',')?.slice(3)?.length} items={data?.deviceId?.split(',')?.slice(3).map((key) => userData.find((item) => item.deviceId === key)?.label)} type={'deviceId'} />
                            </>
                        )
                    )}

                    {/* {newChat && data?.deviceId?.length > 0 && data?.deviceId?.slice(0, 3).map((key) => <Chip sx={{ borderColor: "#00B906", borderWidth: "2px" }} variant='outlined' label={key} />)}<Popover number={data?.deviceId?.slice(3,).length} items={data?.deviceId?.slice(3,)} type={'deviceId'} />
                    {!newChat && data?.deviceId?.length > 0 && data?.deviceId?.split(',').slice(0, 3).map((key) => <Chip sx={{ borderColor: "#00B906", borderWidth: "2px" }} variant='outlined' label={key} />)}<Popover number={data?.deviceId?.split(',').slice(3,).length} items={data?.deviceId?.split(',').slice(3,)} type={'deviceId'} /> */}
                </div>
                <div>

                </div>
                <div>

                </div>
            </CardContent>
        </React.Fragment></Card>
    </Box>
        ;
}