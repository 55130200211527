export const loginApi = async (data) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(data);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    const response = await fetch("https://envdevapi.oizom.com/users/login", requestOptions)
        
    return response

}

export const overviewApi = async (userID, token) => {
    const myHeaders = new Headers();
    myHeaders.append("x-access-token", `Bearer ${token}`);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://envdevapi.oizom.com/users/${userID}/overview`, requestOptions)
        .then((response) => response.json())
    return response
}

export const get_all_threads = async (userID) => {
    try {
        const threadArray = await fetch(`https://gptapi.oizom.com/api/threads/all/${userID}`)
        const threadJson = await threadArray.json()
        return threadJson
    } catch (error) {
        console.log(error)
        return {
            error: 'All the threads were not fetched.'
        }
    }

}

export const get_thread_files = async (threadId) => {
    try {
        const threadArray = await fetch(`https://gptapi.oizom.com/api/files/all/${threadId}`)
        const threadJson = await threadArray.json()
        return threadJson
    } catch (error) {
        return {
            error: 'All the files were not fetched.'
        }
    }

}

export const fetchFile = async (fileId) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    try {
        const response = await fetch(`https://gptapi.oizom.com/api/files/${fileId}`, requestOptions)
        return response
    }
    catch (err) {
        console.log(err.message)
        return {
            error: 'File were not fetched.'
        }
    }

}

export const createNewThread = async (data) => {
    try {
        let response = await fetch("https://gptapi.oizom.com/api/new", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
        return response.json()
    } catch (err) {

        console.log(err.message)
        return {
            error: 'Thread was not created. May you refresh the page.'
        }
    }
}

export const fetchThread = async (threadId) => {
    try {
        let response = await fetch(`https://gptapi.oizom.com/api/threads/${threadId}`)
        return response.json()
    } catch (err) {
        console.log(err.message)
        return {
            error: 'Could not retrieve the thread. May you refresh the page.'
        }
    }
}

export const fetchRun = async (threadId, runId) => {
    try {
        let response = await fetch(`https://gptapi.oizom.com/api/threads/${threadId}/runs/${runId}`)
        return response.json()
    } catch (err) {
        console.log(err.message)
        return {
            error: 'Server Error. May you refresh the page.'
        }
    }
}

export const postMessage = async (threadId, message, userID) => {
    try {
        let response = await fetch(`https://gptapi.oizom.com/api/threads/${threadId}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ content: message, userID: userID })
        })
        return response.json()
    } catch (err) {
        console.log(err.message)
        return {
            error: 'Message was not posted. May you refresh the page and try again.'
        }
    }
}

export const postToolResponse = async (threadId, runId, toolResponses) => {
    try {
        let response = await fetch(`https://gptapi.oizom.com/api/threads/${threadId}/runs/${runId}/tool`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(toolResponses)
        })
        return response.json()
    } catch (err) {
        console.log(err.message)
    }
}

export const getUserTokens = async (userId) => {
    try {
        let response = await fetch(`https://gptapi.oizom.com/api/tokens/${userId}`)
        return response.json()
    } catch (err) {
        console.log(err.message)
        return false
    }
}