import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Checkbox, Typography } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const styles = {
    select: {
        '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#00B906',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#00B906',
            borderWidth: '0.1rem',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "#00B906"
        }
    },
};

function getStyles(name, data, theme) {
    return {
        fontWeight:
            data.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,

    };
}

export default function MultiSelect({ label, list, data, setData, disabled, multiple }) {
    const theme = useTheme();

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        if (!multiple) {
            setData(value);
        }
        else {
            setData(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
            );
        }
    };

    return (
        <div>
            <FormControl disabled={disabled} size='small' sx={{ m: 1, width: 150 }}>
                <InputLabel sx={{ color: '#00B906 !important' }} id="demo-multiple-chip-label">{label}</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple={multiple}
                    value={data}
                    sx={styles.select}
                    onChange={handleChange}
                    input={<OutlinedInput sx={{ color: "red" }} id="select-multiple-chip" value={data} label={label} />}
                    renderValue={(selected) => {
                        if (multiple) {
                            return (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip sx={{ backgroundColor: "green", color: "white" }} key={value} label={value} />
                                    ))}
                                </Box>
                            )
                        }
                        else return <Box sx={{ display: 'flex', fontSize: "small" }}>
                            {selected}
                        </Box>
                    }}
                    MenuProps={MenuProps}
                >
                    {list.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, data, theme)}
                        >
                            {/* {multiple && <Checkbox checked={data[label.toLowerCase()]?.indexOf(name) > -1} />} */}

                            {name}
                        </MenuItem>

                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
