import React, { useEffect, useState } from 'react'
import { fetchFile } from '../services/api'
import { CircularProgress } from '@mui/material'

const ChatImage = ({ id }) => {
    const [url, setUrl] = useState('')
    const [imageLoading, setImageLoading] = useState(true)
    useEffect(() => {
        const gettingImage = async (id) => {
            const response = await fetchFile(id)
            const buffer = await response.arrayBuffer()
            const imageData = new Uint8Array(buffer);
            const blob = new Blob([imageData], { type: 'image/png' });
            const url = URL.createObjectURL(blob);
            setUrl(url);
            setImageLoading(false)
        }
        gettingImage(id)
    }, [])
    return (<div>

        {imageLoading ? <CircularProgress sx={{ marginLeft: "10%", color: "#B9E937" }} /> :
            <img width={"65%"} height={"65%"} src={url} alt='generated'/>}
    </div>

    )
}

export default ChatImage