import { MdSend } from "react-icons/md";
import { useState } from "react";

export default function ChatInput({ disabled, onSend, setMessages, messages, setError, setStatus }) {
    const [message, setMessage] = useState("");
    const isMobile = window.innerWidth < 768;
    return (

        <form
            className="border-[1px] border-[#00B906] py-2 pl-4 pr-2 flex flex-row m-2 mt-0 text-[#424242]"
            style={{ borderRadius: "15px", marginBottom: isMobile && 0, padding: isMobile && "4px" }}
            onSubmit={async (e) => {
                e.preventDefault();
                const tokensLeft = document.getElementById('tokensLeft').textContent
                if (Number(tokensLeft) < 0) {
                    setError('You have exceeded your token limit. Please upgrade your account or refresh the page if you have already upgraded.')

                    return
                }
                setStatus("Queuing");
                setMessages(prevMessages => [...prevMessages, { content: message, role: "user", id: "new", hidden: false, type: "text", created_at: Math.floor(Date.now() / 1000) }])
                onSend(message);
                setMessage("");
            }}
            autoComplete="off"
        >
            <input
                name="message"
                placeholder="What can I do for you?"
                className={
                    "w-full " +
                    "focus:outline-none "
                }
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                style={{ fontSize: "large" }}
            />
            <button
                disabled={disabled}
                className={
                    "bg-[#00B906] " +
                    "text-white " +
                    "font-bold " +
                    "py-3 px-3 " +
                    "hover:bg-[#B9E937] " +
                    "disabled:bg-slate-600 " +
                    "disabled:text-slate-400"
                }
                style={{ borderRadius: "10px", fontSize: "large" }}
            >
                <MdSend />
            </button>
        </form>

    )
}