import { useState, useEffect } from 'react';
import { createNewThread, fetchThread } from "../services/api";
import { runFinishedStates } from "./constants";

export const useThread = (run, setRun, setMainLoading, setNewChat, threadList) => {
    const [threadId, setThreadId] = useState(undefined);
    const [thread, setThread] = useState(undefined);
    const [error, setError] = useState(undefined);
    // const [actionMessages, setActionMessages] = useState([]);
    const [messages, setMessages] = useState([]);
    // This hook is responsible for creating a new thread if one doesn't exist
    useEffect(() => {
        if (threadList.length > 0) {
            if (threadId === undefined) {
                const localThreadId = localStorage.getItem("thread_id");
                if (localThreadId && !threadList.map((threadId) => threadId.ThreadID).includes(localThreadId)) {
                    setMainLoading(false)
                    setNewChat(true)
                    return
                }
                if (localThreadId) {
                    console.log(`Resuming thread ${localThreadId}`);
                    setThreadId(localThreadId);

                    fetchThread(localThreadId).then((thread) => {
                        if (Object.keys(thread).includes("error")) {
                            setError(thread.error)
                        }
                        else {
                            setThread(thread)
                        }
                    })
                } else {
                    if (run && !runFinishedStates.includes(run.status)) {
                        return;
                    }
                    console.log("run finish waala");
                    setMainLoading(false)
                    setNewChat(true)
                }
            }
        }
    }, [threadId, setThreadId, setThread, setRun, threadList, error]);

    const settingNewThread = async (infoData) => {
        await setMainLoading(true)
        console.log("Creating new thread");
        createNewThread(infoData).then((data) => {
            if (Object.keys(data).includes("error")) {
                setError(data.error)
                return
            }
            setRun(data);
            setThreadId(data.thread_id);
            localStorage.setItem("thread_id", data.thread_id);
            console.log(`Created new thread ${data.thread_id}`);
        });
    }
    // This hook is responsible for fetching the thread when the run is finished
    useEffect(() => {
        if (!run || !runFinishedStates.includes(run.status)) {
            return;
        }

        console.log(`Retrieving thread ${run.thread_id}`);
        fetchThread(run.thread_id)
            .then((threadData) => {
                if (Object.keys(threadData).includes("error")) {
                    setError(threadData.error)
                    setThread(undefined)
                    return
                } else {
                    setThread(threadData)
                }
            });
    }, [run, runFinishedStates, setThread]);

    // This hook is responsible for transforming the thread into a list of messages
    useEffect(() => {
        if (!thread) {
            setMainLoading(true)
            return;
        }
        setNewChat(false)
        console.log(`Transforming thread into messages`);
        let newMessages = [...thread.messages]
            .sort((a, b) => a.created_at - b.created_at)
            .filter((message) => message.hidden !== true)
        setMessages(newMessages);

        setMainLoading(false)
    }, [thread, setMessages, error]);

    const clearThread = () => {
        localStorage.removeItem("thread_id");
        setThreadId(undefined);
        setThread(undefined);
        setRun(undefined);
        setMessages([]);
    }

    const changeThread = (threadId) => {
        setMainLoading(true)
        localStorage.setItem('thread_id', threadId)
        setThreadId(undefined)
        setNewChat(false)
    }

    return {
        threadId,
        messages,
        clearThread,
        setMessages,
        changeThread,
        thread,
        settingNewThread,
        setError,
        error
    };
};