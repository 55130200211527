import Markdown from 'react-markdown'
import { SiOpenai } from "react-icons/si";
import remarkGfm from "remark-gfm";
import ChatImage from './ChatImage';
import { Button } from '@mui/material';
import { fetchFile } from '../services/api';
import ChatChart from './ChatChart';

export default function ChatMessage({ message, role, type, annotations }) {
    const Formatter = () => {
        if (annotations?.length > 0) {
            let endIndex = message.indexOf("\n");
            let extractedText = message.substring(0, endIndex);



            return <><Markdown
                className="text-[#424242] markdown"
                remarkPlugins={[remarkGfm]}
            >
                {extractedText}

            </Markdown>
                {annotations.map((obj) => {
                    return <Button onClick={() => getLinkDownload(obj.file_path.file_id, obj.text.split("/")[3])} sx={{ display: "flex", backgroundColor: "#00B906", fontSize: "70%", margin: "7px 0px" }} size='small' variant='contained' color='success' >{obj.text.split("/")[3]}</Button>
                })}
            </>
        }
        return <Markdown
            className="text-[#424242] markdown"
            remarkPlugins={[remarkGfm]}
        >
            {message}
        </Markdown>
    }

    const getLinkDownload = async (file_id, file_name) => {
        const response_file = await fetchFile(file_id)
        const blob = await response_file.blob();

        // Create a temporary URL for the Blob
        const blobUrl = URL.createObjectURL(blob);

        // Create a temporary anchor element
        const tempAnchor = document.createElement('a');
        tempAnchor.href = blobUrl;
        tempAnchor.setAttribute('download', file_name); // Specify the filename
        tempAnchor.style.display = 'none';
        document.body.appendChild(tempAnchor);

        // Simulate click on the anchor to initiate download
        tempAnchor.click();

        // Clean up
        URL.revokeObjectURL(blobUrl);
        tempAnchor.remove();
    }

    switch (type) {
        case "text":
            const roleIcon = role === "user"
                ? <div className="rounded-full h-8 w-8 bg-[#424242] flex items-center justify-center font-semibold text-slate-50 shrink-0 mt-[2px]">U</div>
                : <div className="rounded-full h-8 w-8 bg-[#00B906] flex items-center justify-center font-semibold text-slate-50 shrink-0 mt-[2px]"><SiOpenai /></div>

            const roleName = role === "user"
                ? "User"
                : "Assistant"

            return (
                <div className="flex flex-row mx-2 my-4">
                    {roleIcon}
                    <div className="p-1 ml-2">
                        <div className="flex-col">
                            <p className="font-semibold text-[#00B906] mb-[2px]" style={{ fontSize: "large", color: role === "user" ? "#424242" : "" }}>{roleName}</p>
                            <Formatter />
                        </div>
                    </div>
                </div>
            )
        case "image_file":
            return <ChatImage id={message} />
        case "chart":
            if (typeof (message) === "string") {
                const chartOptions = extractJsonFromMarkdown(message)
                
                if (chartOptions === null) {
                    return <p style={{ color: "red", fontSize: "small", margin: "0px auto" }}>Error parsing chart options.</p>
                }
                
                return <ChatChart options={chartOptions} />
            }
            else if (typeof (message) === "object") {
                return <ChatChart options={message} />
            }

        default:
            break;
    }

}




function extractJsonFromMarkdown(message) {
    try {
        var jsonStringWithoutComments = message.replace(/\/\/.*?\n|\/\*.*?\*\/|(\.\.\.)/g, '');
        if (jsonStringWithoutComments.startsWith('json')) {
            
            jsonStringWithoutComments = jsonStringWithoutComments.substring(4);

        }
        const jsonObject = JSON.parse(jsonStringWithoutComments);
        return jsonObject;
    } catch (error) {
        // console.log(error);
        return null;
    }
}

